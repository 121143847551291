window.isOnMobileDevice = function() {
  return (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i));
}

window.parseQuery = function() {
  const s = window.location.search.replace(/^\?/, '');

  if (s === '') return {};

  return s.split('&').reduce(function(acc, item) {
    const pair = item.split('=');

    acc[pair[0]] = decodeURIComponent(pair[1]);

    return acc;
  }, {})
}

window.isMobile = function() {
  return window.innerWidth <= 768;
}

window.resolveTop = function(el) {
  let result = 0;

  let current = el;
  while(current.parentElement) {
    result += current.offsetTop;
    current = current.parentElement;
  }

  return result;
}

window.precalculateElData = function (el) {
  const elrect = el.getBoundingClientRect();
  const eltop = elrect.top + window.scrollY;

  return {
    el: el,
    top: eltop,
    height: elrect.height,
    target: eltop,
  }
}

window.checkVideoPlayback = function(scrollTop, screenBottom, data) {
  const vids = data.videos;

  for (let i = 0; i < vids.length; i ++) {
    const vid = vids[i];
    const isVisible = vid.top < screenBottom && vid.top + vid.height > scrollTop;

    if (!vid.el.muted) {
      vid.el.muted = true;
    }

    if (!isVisible) {
      if (!vid.el.paused) vid.el.pause();
    } else {
      if (vid.el.paused) {
        vid.el.play().catch(function(err) {
          if (!vid.el.suspended) {
            vid.el.pause();

            const parent = vid.el['__parent'];
            if (parent) {
              parent.classList.add('__load');
            }

            vid.el.suspended = true;
            vid.el.load();
          }
        })
      }
    }
  }
}

window.checkFadeTrigger = function (scrollTop, screenBottom, data) {
  const els = data.fadedEls;
  const screenTarget = scrollTop + data.windowFadeThreshold;

  for (let i = 0; i < els.length; i++) {
    const el = els[i];
    if (el.done) continue;

    const isHalfVisible = el.target < screenTarget;

    if (isHalfVisible) {
      requestAnimationFrame(function () {
        el.el.classList.add(`fade-animation-${el.el.dataset.fade || 'up'}`);
        el.el.classList.add('fade-done');

        el.done = true;
      });
    }
  }
}

window.scheduleFade = function(el, delay) {
  setTimeout(function() {
    el.classList.add(`fade-animation-${el.dataset.fade || 'up'}`);
    el.classList.add('fade-done');
  }, delay);
}

window.computeParallax = function(scrollTop, screenMiddle, elData) {
  const marker = elData.target - screenMiddle;
  elData.el.style.setProperty('--parallax-value', `${marker}px`);
}

window.setActiveNavbarItem = function(navItemTitle) {
  const navbarHtmlElements = document.querySelectorAll('.navbar-list-item');
  for (let i = 0; i < navbarHtmlElements.length; i++) {
    navbarHtmlElements[i].classList.remove('active');
  }
  for (let i = 0; i < navbarHtmlElements.length; i++) {
    if(navItemTitle === navbarHtmlElements[i].innerText) {
      localStorage.setItem("navbarActiveIndex", i);
      navbarHtmlElements[i].classList.add('active');
      break;
    }
  }
}

window.toArray = function(arrObj) {
  return Array.prototype.slice.call(arrObj);
}

window.mathClamp = function(value, min, max) {
  return Math.max(min, Math.min(value, max));
}

window.handleVideos = function() {
  const videos = [...document.querySelectorAll('.video-container')];

  videos.forEach(function(video) {
    const videoEl = video.querySelector('video');
    const videoBtn = video.querySelector('.video-container-button');

    videoEl['__parent'] = video;

    videoBtn.addEventListener('click', function() {
      if (videoEl.paused) {
        videoEl.play();
      }
    });

    videoEl.suspended = false;
    videoEl.addEventListener('loadeddata', function() {
      console.log('load', videoEl.suspended);

      video.classList.remove('__load');
      if (videoEl.suspended) {
        video.classList.add('__show');
      }
    });

    videoEl.addEventListener('play', function() {
      videoEl.suspended = false;
      video.classList.remove('__show');
    });
  });
};
